import React from "react";
import styles from "./styles.module.css";
import { Link } from "gatsby";
import Button from "../../../components/Button";
import email1 from "../../../../../assets/img/email1.gif";
import email2 from "../../../../../assets/img/email2.gif";
import email3 from "../../../../../assets/img/email3.gif";
import email4 from "../../../../../assets/img/email4.gif";

export default function ForfaitsCourriels() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Forfaits courriels</h1>
        <p>
          NEBS offre un vaste éventail de solutions de conception Web à des
          tarifs abordables. Que vous possédiez une petite entreprise et
          cherchiez à monter votre premier site Web ou que vous exploitiez une
          grande société et désiriez étendre votre offre en ligne, nous avons
          une solution pour vous.
        </p>
        <h2>Forfaits courriels standards</h2>
        <p>
          EasyMail Set-up est un outil complet qui permet à n'importe quel
          utilisateur novice de gérer facilement des comptes courriel associés à
          son domaine et d'en configurer à sa guise les différentes
          caractéristiques. On accède à ces comptes par le biais des
          gestionnaires de courriel traditionnels aussi bien que sur le Web avec
          Webmail.
        </p>
      </div>
      <div className={styles.row}>
        <div className={styles.column_3}>
          <div className={styles.card}>
            <span>3 courriels</span>
            <img src={email1} alt="Symbole @ bronze"></img>
            <span>3,99 $ /mois</span>
            <div className={styles.button_wrapper}>
              <Button text={"ACHETEZ MAINTENANT"} margin={"10px 0"} />
            </div>
            Boîte aux lettres supplémentaire : 0,49 $ chacune/mois
          </div>
        </div>
        <div className={styles.column_3}>
          <div className={styles.card}>
            <span>10 courriels</span>
            <img src={email2} alt="Symbole argenté @"></img>
            <span>9,99 $ /mois</span>
            <div className={styles.button_wrapper}>
              <Button text={"ACHETEZ MAINTENANT"} margin={"10px 0"} />
            </div>
            Boîte aux lettres supplémentaire : 0,46 $ chacune/mois
          </div>
        </div>
        <div className={styles.column_3}>
          <div className={styles.card}>
            <span>100 courriels</span>
            <img src={email3} alt="Symbole @ or"></img>
            <span>89,99 $ /mois</span>
            <div className={styles.button_wrapper}>
              <Button text={"ACHETEZ MAINTENANT"} margin={"10px 0"} />
            </div>
            Boîte aux lettres supplémentaire : 0,43 $ chacune/mois
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <h2>
          Forfaits courriels avec <Link>SyncSuite</Link> | Qu'est-ce que{" "}
          <Link>SyncSuite</Link>
        </h2>
        <p>
          Non seulement EasyMailPro en association avec SyncSuite [insert French
          link] offre-t-il les fonctionnalités courriel les plus importantes des
          services réguliers EasyMail, il confère aussi la possibilité de
          partager calendriers, carnets d'adresses, listes de tâches et notes à
          travers votre domaine avec vos collègues tout en synchronisant
          calendriers et carnets d'adresses entre de multiples dispositifs. Que
          vous travailliez de la maison ou d'un bureau à distance, que vous
          soyez un voyageur de commerce, ou encore membre d'une équipe sportive
          ou d'une commission, EasyMailPro en association avec SyncSuite est
          tout ce que vous avez toujours recherché pour établir et garder le
          contact.
        </p>
      </div>
      <div className={styles.row}>
        <div className={styles.column_4}>
          <div className={styles.card}>
            <span>1 courriels</span>
            <img src={email1} alt="Symbole @ bronze"></img>
            <span>6,00 $ /mois</span>
            <div className={styles.button_wrapper}>
              <Button text={"ACHETEZ MAINTENANT"} margin={"10px 0"} />
            </div>
            Boîte de courriel supplémentaire : 2,00 $ chacune/mois
          </div>
        </div>
        <div className={styles.column_4}>
          <div className={styles.card}>
            <span>3 courriels</span>
            <img src={email2} alt="Symbole argenté @"></img>
            <span>14,99 $ /mois</span>
            <div className={styles.button_wrapper}>
              <Button text={"ACHETEZ MAINTENANT"} margin={"10px 0"} />
            </div>
            Boîte de courriel supplémentaire : 1,73 $ chacune/mois
          </div>
        </div>
        <div className={styles.column_4}>
          <div className={styles.card}>
            <span>10 courriels</span>
            <img src={email3} alt="Symbole @ or"></img>
            <span>39,99 $ /mois</span>
            <div className={styles.button_wrapper}>
              <Button text={"ACHETEZ MAINTENANT"} margin={"10px 0"} />
            </div>
            Boîte de courriel supplémentaire : 1,64 $ chacune/mois
          </div>
        </div>
        <div className={styles.column_4}>
          <div className={styles.card}>
            <span>100 courriels</span>
            <img src={email4} alt="Symbole platium @"></img>
            <span>349,99 $ /mois</span>
            <div className={styles.button_wrapper}>
              <Button text={"ACHETEZ MAINTENANT"} margin={"10px 0"} />
            </div>
            Boîte de courriel supplémentaire : 1,55 $ chacune/mois
          </div>
        </div>
      </div>
    </div>
  );
}
