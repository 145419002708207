import React from "react";
import Layout from "../../../../other/layout";
import ForfaitsCourriels from "../../../../components/AC-StaticPages/Developpez/ServicesWeb/ForfaitsCourriels/ForfaitsCourriels";
import SEO from "../../../../other/seo";

export default function forfaitsCourriels() {
  return (
    <Layout>
      <SEO title="Forfaits Courriels" />
      <ForfaitsCourriels />
    </Layout>
  );
}
